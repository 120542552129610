import { ReactNode } from 'react';

export const Policy = (): ReactNode => {
  return (
    <p style={{ lineHeight: 1.7 }}>
      Last updated: February, 2024 <br />
      This Privacy Policy explains how we collect, use, and disclose personal information that you provide to us when
      you participate in our sweepstakes. Please read this Privacy Policy carefully to understand our practices
      regarding your personal information and how we will treat it.
      <br />
      <br />
      <b>Information We Collect</b> <br />
      1.1 Personal Information: When you participate in our sweepstakes, we may collect personal information that you
      provide voluntarily, such as: <br />
      <div style={{ marginLeft: 30 }}>
        Name <br />
        Email address <br />
        Mailing address <br />
        Phone number <br />
      </div>
      <br />
      1.2 Non-Personal Information: We may also collect non-personal information automatically when you interact with
      our website. This may include your IP address, browser type, device information, and other technical information.{' '}
      <br />
      <br />
      <b>Use of Information</b>
      <br />
      2.1 Personal Information: We may use the personal information you provide for the following purposes:
      <br />
      To administer the sweepstakes and contact you regarding the prize or any related information. To send you
      marketing communications, newsletters, or promotional materials related to our products or services. You may
      opt-out of receiving these communications at any time.
      <br />
      <br />
      2.2 Non-Personal Information: We may use non-personal information for various purposes, including:
      <br />
      <div style={{ marginLeft: 30 }}>
        Analyzing trends and usage patterns. <br />
        Improving our website and services. <br />
        Enhancing the overall user experience. <br />
      </div>
      <br />
      <b>Disclosure of Information</b> <br />
      3.1 We may disclose your personal information to third parties in the following circumstances:
      <br />
      To service providers or partners who assist us in operating our website, conducting the sweepstakes, or delivering
      services to you.
      <br />
      To comply with applicable laws, regulations, or legal processes.
      <br />
      To protect our rights, property, or safety, as well as the rights, property, or safety of our users or others.
      <br />
      In connection with a merger, acquisition, or sale of all or a portion of our business.
      <br />
      3.2 We may share non-personal information with third parties for any lawful purpose.
      <br />
      <br />
      <b>Security</b>
      <br />
      We take reasonable measures to protect the security of your personal information and maintain its confidentiality.
      However, please be aware that no security measure is completely foolproof, and we cannot guarantee the absolute
      security of your information.
      <br />
      <br />
      <b>Third-Party Links</b> <br />
      Our website may contain links to third-party websites or services that are not controlled or operated by us. This
      Privacy Policy does not apply to those third-party websites or services. We recommend reviewing the privacy
      policies of such websites or services before providing any personal information.
      <br />
      <br />
      <b>Children's Privacy</b>
      <br />
      Our sweepstakes are intended for individuals above the age of [minimum age]. We do not knowingly collect personal
      information from children under [minimum age]. If we become aware that we have collected personal information from
      a child under [minimum age], we will take steps to remove the information promptly.
      <br />
      <br />
      <b>Changes to this Privacy Policy</b>
      <br />
      We may update this Privacy Policy from time to time. Any changes we make will be posted on this page, and the
      "Last updated" date at the beginning of this policy will be revised. We encourage you to review this Privacy
      Policy periodically to stay informed about how we collect, use, and disclose personal information.
      <br />
      <br />
      <b>Contact Us </b>
      <br />
      If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please
      contact us at
      <a href='support@jackpot-ny.buzz'> support@jackpot-ny.buzz</a>.
    </p>
  );
};
